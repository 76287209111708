.feed-top-box {
    position: absolute;
    z-index: var(--zindex-feed-top-button);
    left: 0;
    width: 100%;
    display: block;
    text-align: center;
}

@media only screen and (min-width: 576px) {
    .feed-top-box {
        bottom: -.8rem;
    }
}

@media only screen and (max-width: 575px) {
    .feed-top-box {
        bottom: -2.2rem;
    }
}

.feed-top-button {
    background-color: var(--bs-gray-100);
    color: var(--bs-info);
    display: inline-block;
    padding: .2rem .7rem;
    border-radius: 1em;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14),
                0 1px 10px 0 rgba(0, 0, 0, .12),
                0 2px 4px -1px rgba(0, 0, 0, .2);
    cursor: pointer;
    font-size: .875rem;
    font-weight: 400;
}

.feed-top-button:hover {
    background-color: var(--bs-info);
    border-color: var(--bs-info);
    color: white;
}

.feed-top-button .new {
    background-color: var(--bs-primary);
    color: white;
    font-weight: bold;
    padding: .15em .35em .15em .3em;
    border-radius: .3em;
    margin-left: .5em;
}
