.feed-sentinel {
    text-align: center;
    font-size: large;
    font-weight: bold;
    height: 1.6em;
    color: var(--bs-cyan);
    border: .1em var(--body-background) solid;
    border-radius: .5em;
    background-color: var(--bs-gray-100);
    cursor: pointer;
}

.feed-sentinel.bottom {
    margin-bottom: 1rem;
}

.feed-sentinel.loading {
    background-color: transparent;
    border-color: transparent;
}

.feed-sentinel:hover {
    background-color: var(--highlight);
}
