#vertical-menu {
    border-radius: 3px;
    padding: 0.5rem;
    border: solid var(--status-icon) 1px;
}

#vertical-menu .navbar-nav {
    line-height: 1.1;
    padding: 0 .5rem 1rem .5rem;
}

#vertical-menu .nav-item .nav-link {
    text-transform: uppercase;
}

#vertical-menu .connection-button {
    cursor: pointer;
    color: var(--status-icon);
    font-size: 150%;
}

#vertical-menu .connection-button:hover {
    color: var(--status-icon-highlight);
}

#vertical-menu .home-avatar {
    margin-left: 0;
}
