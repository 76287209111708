.node-name {
    font-weight: bold;
}

.node-name:hover {
    text-decoration: underline;
}

.node-name.correct {
    color: var(--correct);
}

.node-name.incorrect {
    color: var(--incorrect);
    text-decoration: line-through;
}

.node-name .generation {
    font-size: 60%;
    position: relative;
    bottom: -0.25em;
}

.node-name .mention {
    color: var(--bs-gray-600);
    font-weight: normal;
}
