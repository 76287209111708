.reactions {
    font-size: .9rem;
    font-weight: 600;
    padding-top: .2em;
    padding-left: .2em;
    margin-top: .2em;
}

.reactions .emojis {
    font-family: sans-serif;
    font-size: 1.1em;
    margin-right: .55em;
}

.reactions .positive, .reactions .negative {
    border-radius: .9em;
    padding: .44em 1.1em;
}

.reactions .positive {
    margin-right: 1.45em;
    color: var(--correct);
    background-color: var(--reactions-positive);
}

.reactions .negative {
    color: var(--incorrect);
    background-color: var(--reactions-negative);
}

.reactions .positive:hover, .reactions .negative:hover {
    text-decoration: underline;
    cursor: pointer;
}

.reactions .senior-positive, .reactions .senior-negative {
    border-radius: 1em;
    font-size: 1.3em;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .12);
    padding: .3em .5em;
}

.reactions .senior-positive:hover, .reactions .senior-negative:hover {
    cursor: pointer;
}

.reactions .senior-positive {
    color: var(--correct);
    background-color: var(--reactions-positive);
    border: solid 1px var(--reactions-positive-border);
}

.reactions .senior-negative {
    color: var(--incorrect);
    background-color: var(--reactions-negative);
    border: solid 1px var(--reactions-negative-border);
}
