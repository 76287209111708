.connection-status .home-name {
    margin-right: 1rem;
    position: relative;
    top: -0.1rem;
    font-weight: bold;
    cursor: pointer;
}

.home-name .name {
    margin-left: .25rem;
}

.home-name .name .generation {
    font-size: 60%;
    position: relative;
    bottom: -0.25em;
}

.connection-status .active .home-name .name {
    color: white;
}

.connection-status .home-name .name::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: relative;
    top: 0.1em;
}

.connection-status .home-name:hover .name {
    color: var(--status-icon-highlight);
}

.connection-status .home-name.anonymous {
    font-weight: normal;
    font-style: italic;
}
