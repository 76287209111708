.posting .subject {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    color: var(--node-name);
    overflow-wrap: break-word;
}

.posting .subject A {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
}
