.emoji-list-input {
    display: flex;
}

.emoji-list-input .content {
    flex: auto;
    overflow: hidden;
    cursor: text;
}

.emoji-list-input .button {
    flex: none;
    cursor: pointer;
    margin: -.375rem -.75rem;
    padding: .375rem .75rem;
}

.emoji-list-input .button:hover {
    background-color: var(--highlight);
}
