.modal {
    display: block;
}

.modal .loading-overlay {
    z-index: var(--zindex-modal-loading);
}

.modal-children {
    max-height: calc(100vh - 8.5rem);
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior-y: none;
}

.modal-empty {
    color: var(--bs-gray-500);
    text-align: center;
    font-style: italic;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.modal-feed {
    --bs-modal-width: var(--feed-width);
}
