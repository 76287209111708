#feed-title {
    background-color: var(--bs-gray-100);
    padding: .5rem 1rem;
}

#feed-title .panel {
    width: min(var(--feed-width), 100%);
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

#feed-title .panel .avatar-link {
    flex: none;
    margin-right: .5rem;
}

#feed-title .panel .body {
    flex: auto;
    padding: .5rem 0;
    line-height: 1.2;
}

#feed-title .full-name {
    font-size: 1.9rem;
    font-weight: bold;
    overflow-wrap: break-word;
}

#feed-title .mention {
    color: var(--bs-gray-500);
    white-space: nowrap;
}

#feed-title .title {
    font-size: larger;
    margin-top: .5em;
}
