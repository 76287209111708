.entry .gallery {
    display: flex;
    position: relative;
}

.posting .gallery {
    width: 100%;
}

@media only screen and (min-width: 576px) {
    .comment .gallery {
        width: calc(min(var(--feed-width), 100vw) * 0.5);
    }
}

@media only screen and (max-width: 575px) {
    .comment .gallery {
        width: calc(min(var(--feed-width), 100vw - 55px));
    }
}

.entry .gallery.horizontal {
    flex-direction: row;
    aspect-ratio: 9 / 6;
}

.entry .gallery.vertical {
    flex-direction: column;
    aspect-ratio: 9 / 7;
}

.entry .gallery .gallery-row {
    flex: 1 1 0;
    display: flex;
    min-width: 0;
    min-height: 0;
}

.entry .gallery.horizontal .gallery-row {
    flex-direction: column;
}

.entry .gallery.vertical .gallery-row {
    flex-direction: row;
}

.entry .gallery .entry-image {
    display: block;
    border: 1px solid white;
    flex: 1;
    min-width: 0;
    min-height: 0;
}

.entry .gallery .entry-image IMG {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.entry .gallery.single {
    display: block;
    border: 1px solid var(--bs-gray-200);
    width: unset;
    height: unset;
    aspect-ratio: unset;
}

.entry .gallery.single .entry-image {
    border: 0;
}

.entry .gallery.single .entry-image IMG {
    object-fit: contain;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.posting .gallery.single .entry-image IMG {
    max-width: calc(min(var(--feed-width), 100vw - 10px));
}

@media only screen and (min-width: 576px) {
    .posting .gallery.single .entry-image IMG {
        max-height: min(560px, var(--image-height));
    }

    .comment .gallery.single .entry-image IMG {
        max-width: calc(min(var(--feed-width), 100vw) * 0.5);
        max-height: min(250px, var(--image-height));
    }
}

@media only screen and (max-width: 575px) {
    .posting .gallery.single.horizontal .entry-image IMG {
        max-height: min(75vh, var(--image-height));
    }

    .posting .gallery.single.vertical .entry-image IMG {
        max-height: min(100vh, var(--image-height));
    }

    .comment .gallery.single .entry-image IMG {
        max-width: calc(min(var(--feed-width), 100vw - 55px));
    }

    .comment .gallery.single.horizontal .entry-image IMG {
        max-height: min(25vh, var(--image-height));
    }

    .comment .gallery.single.vertical .entry-image IMG {
        max-height: min(33vh, var(--image-height));
    }
}

.entry .gallery .gallery-expand {
    position: absolute;
    top: .25rem;
    right: .25rem;
    background-color: var(--bs-gray-800);
    border: 0;
    border-radius: .25em;
    color: var(--bs-gray-300);
    opacity: 0;
    transition: opacity .15s ease-in-out;
    font-size: 1.3em;
    display: block;
    width: 1.75em;
    height: 1.75em;
    z-index: var(--zindex-text-overlay);
}

@media only screen and (max-width: 575px) {
    .entry .gallery .gallery-expand {
        opacity: .5;
        font-size: 1em;
    }
}

.entry .gallery:hover .gallery-expand {
    opacity: .5;
}

.entry .gallery:hover .gallery-expand:hover {
    opacity: 1;
}
