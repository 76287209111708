.comment .owner-line {
    margin-bottom: 0;
    padding-right: 1.7rem;
}

.comment .owner {
    font-weight: bold;
    margin-left: .3em;
    margin-right: .5em;
}

.comment .owner > A {
    color: var(--node-name);
}

.comment.topic-starter .owner > A {
    color: var(--node-name-ts);
}
