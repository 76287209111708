.dropdown-menu {
    pointer-events: none;
    margin: unset;
    line-height: var(--bs-body-line-height);
}

.dropdown-menu.show {
    pointer-events: auto;
}

.dropdown-menu .caption {
    font-weight: bold;
    margin-bottom: .5rem;
    margin-left: .6rem;
}

.dropdown-menu .dropdown-item {
    cursor: pointer;
}

.dropdown-menu A.dropdown-item:hover {
    text-decoration: none;
}
