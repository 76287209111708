.delete-button {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--bs-danger);
    font-size: 1.5em;
    height: 1em;
    width: 1em;
    line-height: 1;
    cursor: pointer;
}
