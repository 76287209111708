.emoji-selector .choice IMG {
    vertical-align: baseline;
}

.emoji-selector .choice .twemoji {
    vertical-align: baseline;
}

.emoji-selector .choice .svg-inline--fa {
    vertical-align: .125em;
}

.emoji-selector .choice {
    flex: none;
    position: relative;
    width: 3rem;
    height: 3.3rem;
    text-align: center;
    padding-top: .6rem;
    padding-bottom: .3rem;
}

.emoji-selector .choice:hover {
    background-color: var(--highlight);
    border-radius: 5px;
}

.emoji-selector .choice.dimmed {
    filter: opacity(20%);
}

.emoji-selector .choice.dimmed:hover {
    filter: opacity(60%);
}

.emoji-selector .choice .marker {
    position: absolute;
    z-index: var(--zindex-text-overlay);
    right: 0;
    top: 0;
    font-size: 0.8rem;
    color: var(--bs-primary);
}
