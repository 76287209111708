.error-pane {
    position: fixed;
    z-index: var(--zindex-popover);
}

.error-pane.error-pane-visible {
    visibility: visible;
    top: 0;
    transition: top 0.5s, visibility 0.5s step-start;
}

.error-pane.error-pane-hidden {
    visibility: hidden;
    top: -7rem;
    transition: top 0.5s, visibility 0.5s step-end;
}
