.no-postings {
    font-weight: bold;
    color: var(--bs-gray-400);
    font-size: 4rem;
    margin-top: 5rem;
    text-align: center;
}

.feed-end {
    text-align: center;
    font-size: x-large;
    font-style: italic;
    font-weight: bold;
    color: var(--bs-gray-400);
    padding-bottom: 1rem;
}

@media only screen and (max-width: 575px) {
    .feed-end {
        font-size: large;
        padding-bottom: 2rem;
    }
}
