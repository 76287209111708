:root {
    /*
     * Bootstrap colors (for reference)
     *
     * --bs-gray-900: #212529;
     * --bs-gray-800: #343a40; (dark)
     * --bs-gray-700: #495057;
     * --bs-gray-600: #6c757d; (normal)
     * --bs-gray-500: #adb5bd; (medium)
     * --bs-gray-400: #ced4da; (light)
     * --bs-gray-300: #dee2e6;
     * --bs-gray-200: #e9ecef; (ultralight)
     * --bs-gray-100: #f8f9fa;
     *
     * --bs-blue-600: #0a58ca;
     * --bs-blue-200: #9ec5fe;
     * --bs-blue-100: #cfe2ff;
     *
     * --bs-cyan-200: #9eeaf9;
     */

    /* New design colors */
    --primary-50: #eefafa; /* Future primary */
    --primary-600: #0fb6bf;
    --primary-800: #0b898f;
    --secondary-600: #515795;
    --secondary-800: #3d4170;

    --body-background: var(--bs-gray-200);
    --highlight: rgba(0, 0, 0, .05);
    --highlight-light: rgba(0, 0, 0, .025);
    --highlight-blue: rgba(0, 123, 255, .1);
    --highlight-red: rgba(220, 53, 69, .1);
    --highlight-cyan: #f0fafa;
    --highlight-primary: var(--highlight-blue);

    --cyan-light: var(--bs-cyan-200);
    --green-light: #42d042; /* forest green + 20% lighter */

    --verifying: var(--bs-cyan);
    --correct: forestgreen;
    --incorrect: var(--bs-red);
    --correct-light: var(--green-light);
    --node-name: #385898;
    --node-name-ts: #389858;

    --status-icon: rgba(255, 255, 255, .5);
    --status-icon-highlight: rgba(255, 255, 255, .75);
    --shadow: rgba(0, 0, 0, .75);

    --reactions-positive: #e9fce5;
    --reactions-negative: #feeff0;
    --reactions-positive-border: #cddfc9;
    --reactions-negative-border: #e1d3d4;
    --comments: #f8faff;
    --comment-button: #385898;
}
