#welcome-navigator {
    margin-top: 2rem;
}

#welcome-navigator H1 {
    text-align: center;
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 575px) {
    #welcome-navigator H1 {
        font-size: 1.9rem;
    }
}

#welcome-navigator .form-group {
    margin-left: auto;
    margin-bottom: 0;
}

#welcome-navigator INPUT {
    margin-right: .25rem;
}

#welcome-navigator BUTTON {
    margin-right: auto;
}
