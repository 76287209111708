.posting {
    position: relative;
    border: var(--bs-gray-400) 1px solid;
    border-radius: .25rem;
    margin-bottom: 1rem;
    padding: .5rem;
}

.posting:not(.not-viewed) {
    background-color: white;
    transition: background-color 3s ease;
}

.posting.not-viewed {
    background-color: #fffee4;
}

@media only screen and (max-width: 575px) {
    .posting {
        border-left-width: 0;
        border-right-width: 0;
        border-radius: 0;
        margin-bottom: .5rem;
    }
}

.posting > .content {
    overflow-wrap: break-word;
    text-align: start;
}

.posting.preview > .content {
    overflow-y: auto;
}

@media only screen and (max-width: 575px) {
    .posting.preview > .content {
        max-height: 170vh;
    }
}

@media only screen and (min-width: 576px) {
    .posting.preview > .content {
        max-height: 100vh;
    }
}

.posting > .content P {
    margin-top: 1rem;
}

.posting > .content P:first-child {
    margin-top: 0;
}

.posting > .content IMG:not(.emoji) {
    width: auto;
    height: auto;
}

.posting > .content IMG, .posting > .content IFRAME, .posting > .content .preload-placeholder {
    max-width: min(100vw - 4rem, var(--feed-width) - 3.5rem, var(--width, 100vw));
    max-height: var(--height, 200vh);
}

.posting.preview > .content IMG, .posting.preview > .content IFRAME, .posting.preview > .content .preload-placeholder {
    max-height: min(35rem, var(--height, 200vh));
}

.posting > .content FIGURE {
    display: inline-block;
}

.posting > .content FIGCAPTION {
    font-size: .875em;
    line-height: 1.5;
    margin-top: .5em;
    color: var(--bs-secondary);
}

.posting > .content .read-more {
    width: 100%;
    font-size: var(--posting-font-magnitude);
    text-align: left;
    padding-left: 0;
    padding-top: 0;
}

.posting > .reactions-line {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
