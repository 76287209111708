#owner-name {
    display: inline-block;
    border: var(--bs-dark) .1rem solid;
    border-radius: .25rem;
    text-decoration: none;
    padding: .5rem;
    cursor: text;
}

@media only screen and (min-width: 576px) {
    #owner-name {
        margin-right: .7rem;
    }
}

#owner-name:hover {
    border-color: var(--status-icon);
}

#owner-name .node-name {
    color: white;
}

#owner-name .node-name:hover {
    text-decoration: none;
}

#owner-name .node-name.correct {
    color: var(--correct-light);
}

#owner-name .node-name.incorrect {
    color: var(--incorrect);
}

#owner-name .node-name .mention {
    color: var(--bs-gray-400);
}
