.entry .content::after {
    display: block;
    content: "";
    clear: both;
}

.entry A:hover {
    text-decoration: underline;
}

.entry.single-emoji .content .emoji {
    font-size: 3em;
}

@media only screen and (min-width: 576px) {
    .float-text-start {
        float: left;
        margin-inline-end: .5rem;
    }

    .float-text-end {
        float: right;
        margin-inline-start: .5rem;
    }
}

.entry .footnotes LI P:last-of-type {
    margin-bottom: 0;
}

.entry .content .preload-placeholder {
    display: inline-block;
    width: var(--width);
    aspect-ratio: var(--aspect-ratio);
}
