#connections .connection-item {
    display: flex;
}

#connections .connection-item.active {
    background-color: var(--highlight-cyan);
}

#connections .connection-item:hover {
    background-color: var(--highlight);
}

#connections .connection-item.active:hover {
    background-color: var(--highlight-cyan);
}

#connections .connection {
    flex: auto;
    padding: 0.5em;
    cursor: pointer;
}

#connections .connection-item.active .connection {
    cursor: default;
}

#connections .connection .node-name {
    font-size: 120%;
}

#connections .connection .no-name {
    font-size: 120%;
    font-style: italic;
    color: var(--bs-gray-600);
}

#connections .connection .connected {
    color: var(--bs-gray-600);
}

#connections .connection-add {
    color: var(--bs-primary);
    cursor: pointer;
    font-size: 120%;
    padding: 0.5em;
}

#connections .connection-add:hover {
    background-color: var(--highlight);
}

#connections .connection-buttons {
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.5em;
}

#connections .connection-buttons .link {
    display: block;
    flex: none;
    color: var(--bs-gray-500);
    font-size: 1.1em;
}

#connections .connection-buttons .link:hover {
    color: var(--bs-primary);
}

#connections .connection-buttons .disconnect {
    flex: none;
    color: var(--bs-gray-500);
    cursor: pointer;
    font-size: 1.3em;
    margin-top: 0.2em;
}

#connections .connection-buttons .disconnect:hover {
    color: var(--bs-red);
}
