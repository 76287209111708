.comment .reactions {
    flex: none;
    font-size: .8rem;
    margin-right: .3rem;
    position: relative;
    top: -.9rem;
}

.comment .reactions .positive {
    margin-right: 0;
    margin-left: .7em;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .12);
    border: solid 1px var(--reactions-positive-border);
    padding: .3em .9em;
}

.comment .reactions .negative {
    margin-left: 1em;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, .12);
    border: solid 1px var(--reactions-negative-border);
    padding: .3em .9em;
}
