.entry .gallery-expanded {
    border-top: 1px solid var(--bs-gray-400);
    margin-top: .5rem;
}

.entry .gallery-expanded .gallery-buttons {
    display: flex;
    border-top: 1px solid var(--bs-gray-400);
    border-bottom: 1px solid var(--bs-gray-400);
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-top: .7rem;
}
