.comments-load-all {
    border: none;
    background-color: transparent;
    font-weight: bold;
    color: inherit;
    padding: .1rem .3rem;
}

.comments-load-all:hover {
    background-color: var(--highlight);
    color: black;
}
