.naming-operation-status {
    font-size: 80%;
    margin-left: 1em;
    white-space: nowrap;
}

.naming-operation-status.success {
    color: var(--correct);
}

.naming-operation-status.failure {
    color: var(--incorrect);
}

.naming-operation-status .failure-reason {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dashed;
}
