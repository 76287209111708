.link-preview IMG {
    flex: none;
    object-fit: contain;
    object-position: top;
}

.link-preview IMG.vertical {
    object-fit: cover;
    object-position: center;
}

@media only screen and (min-width: 576px) {
    .link-preview IMG {
        width: 150px;
        height: auto;
        max-height: 150px;
    }

    .link-preview.large IMG {
        width: 100%;
        max-height: 350px;
    }
}

@media only screen and (max-width: 575px) {
    .link-preview IMG {
        width: 30vw;
        height: auto;
        max-height: 30vw;
    }

    A.link-preview.large IMG {
        width: 99.5vw;
        max-height: 67vw;
    }

    DIV.link-preview.large IMG {
        width: calc(99.5vw - 1rem);
        max-height: calc(67vw - .6rem);
    }
}

.link-preview .image-loading {
    padding-left: 1rem;
    padding-bottom: .5rem;
    border-bottom: solid 1px var(--bs-gray-400);
}
