.replied-to {
    margin-bottom: .3rem;
    font-size: 90%;
    border-left: 3px var(--bs-gray-400) solid;
    padding-left: .5em;
}

.replied-to A {
    color: var(--bs-gray-600);
    text-decoration: none;
}

.replied-to .icon {
    color: var(--bs-gray-500);
    margin-right: .7em;
}

.replied-to .node-name {
    color: var(--bs-gray-600);
    position: relative;
    top: -.1em;
}

.replied-to .heading {
    color: var(--bs-gray-600);
    position: relative;
    top: -.1em;
    margin-left: .5em;
}

.replied-to A:hover .heading {
    text-decoration: underline;
}

.replied-to .unset {
    color: var(--bs-gray-500);
    background-color: transparent;
    border: 0;
    font-weight: bold;
    font-size: 135%;
    margin-top: -.3em;
    margin-right: -.3em;
    float: right;
}

.replied-to .unset:hover {
    background-color: var(--highlight);
}

.glance-comment-popover {
    width: max-content;
    max-height: 33vh;
    overflow: auto;
    overscroll-behavior: none;
}

@media only screen and (min-width: 992px) {
    .glance-comment-popover {
        max-width: calc(var(--feed-width) - 2rem);
    }
}

@media only screen and (max-width: 991px) {
    .glance-comment-popover {
        max-width: calc(100vw - 4rem);
    }
}
