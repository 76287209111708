.comment {
    position: relative;
    margin-bottom: .6rem;
    width: max-content;
    max-width: 100%;
    display: flex;
}

.comment .details {
    flex: auto;
    margin-left: .25rem;
    max-width: calc(100% - .25rem - 36px);
}

.comment .content {
    background-color: var(--comments);
    border-radius: 5px;
    box-sizing: content-box;
    padding: .5rem;
    width: max-content;
    min-width: 16rem;
    max-width: calc(100% - 1rem);
    overflow-wrap: break-word;
    text-align: start;
}

.comment .content P {
    margin-top: 1rem;
}

.comment .content P:first-child {
    margin-top: 0;
}

.comment .content P:last-child {
    margin-bottom: 0;
}

.comment.focused .content {
    border: dashed 1px var(--bs-primary);
}

.comment .content.preview {
    overflow-y: auto;
}

@media only screen and (max-width: 575px) {
    .comment .content.preview {
        max-height: 150vh;
    }
}

@media only screen and (min-width: 576px) {
    .comment .content.preview {
        max-height: 100vh;
    }
}

.comment .content IMG:not(.emoji) {
    width: auto;
    height: auto;
}

.comment .content IMG, .comment .content IFRAME, .comment .content .preload-placeholder {
    max-width: min(100vw - 7rem, var(--feed-width) - 6.5rem, var(--width, 100vw));
    max-height: min(16rem, var(--height, 200vh));
}

.comment .content FIGURE {
    display: inline-block;
}

.comment .content FIGCAPTION {
    font-size: .875em;
    line-height: 1.5;
    margin-top: .5em;
    color: var(--bs-secondary);
}

.comment .content .read-more {
    width: 100%;
    font-size: var(--posting-font-magnitude);
    text-align: left;
    padding-left: 0;
    padding-top: 0;
}

@media (any-hover: hover) {
    .comment .chevron {
        visibility: hidden;
    }

    .comment:hover .chevron {
        visibility: visible;
    }
}

@media only screen and (min-width: 576px) {
    .comment A.link-preview, .comment DIV.link-preview {
        width: 400px;
    }
}

@media only screen and (max-width: 575px) {
    .comment A.link-preview, .comment DIV.link-preview {
        width: calc(100vw - .5rem - 36px);
    }
}

.comment .reactions-line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 1.65em;
}

.comment .reactions-line .unsigned {
    color: var(--bs-gray-400);
    font-size: 90%;
    margin-left: .2em;
    padding-top: .1em;
}
