.flash-box {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, .7);
    color: white;
    border-radius: .35rem;
    padding: .5rem .7rem;
    z-index: var(--zindex-tooltip);
}

.flash-box.dismissing {
    background-color: transparent;
    color: transparent;
    transition: all 1s;
}
