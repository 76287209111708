.form-label-button {
    color: var(--bs-gray-500);
    background-color: transparent;
    border: none;
    border-radius: 3px;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    cursor: pointer;
    align-self: center;
    line-height: 1.1;
}

.form-label-button:hover {
    background-color: var(--highlight);
    color: var(--bs-gray-600);
}

.form-label-button:hover.form-label-button-undo {
    color: var(--bs-cyan);
}

.form-label-button:hover.form-label-button-reset {
    color: var(--bs-red);
}

.form-label-button:hover.form-label-button-default {
    color: var(--bs-primary);
}
