#page-header {
    position: sticky;
    top: 2.7rem;
    height: 3.4rem;
    background-color: white;
    margin-bottom: .5rem;
    z-index: var(--zindex-page-header);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14),
                0 1px 10px 0 rgba(0, 0, 0, .12),
                0 2px 4px -1px rgba(0, 0, 0, .2);
    transition: all .5s;
}

@media only screen and (max-width: 575px) {
    #page-header {
        padding-top: .3rem;
    }
}

#page-header.hidden {
    top: -.75rem;
}

#page-header .panel {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: var(--feed-width);
    padding-right: .25rem;
    margin-left: auto;
    margin-right: auto;
}

#page-header H2 {
    margin-left: .3em;
    line-height: 1.6;
}

#page-header H2 .avatar-link {
    margin-right: .25em;
}

#page-header H2 .btn {
    margin-bottom: .25em;
}

#page-header .btn {
    margin-left: .5rem;
}

#page-header .page-header-buttons {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: var(--zindex-page-header-buttons);
    top: .5rem;
    right: .25rem;
    background: white;
    padding-left: .25rem;
    line-height: 2;
}

@media only screen and (max-width: 575px) {
    #page-header .page-header-buttons {
        top: .3rem;
    }
}

#page-header .page-header-buttons BUTTON {
    white-space: nowrap;
}

#page-header .react-datepicker-wrapper {
    line-height: 1.5;
    margin-left: .5rem;
}
