.node-card {
    padding-top: .5em;
}

@media only screen and (max-width: 575px) {
    .node-card {
        width: 300px;
    }
}

@media only screen and (min-width: 576px) {
    .node-card {
        width: 400px;
    }
}

.node-card .unknown {
    font-style: italic;
    color: var(--bs-gray-600);
    text-align: center;
}

.node-card .main {
    display: flex;
}

.node-card .avatar-link {
    flex: none;
    margin-right: .5em;
}

.node-card .body {
    flex: auto;
}

.node-card .full-name {
    font-weight: bold;
    font-size: 150%;
}

.node-card .gender {
    font-style: italic;
    margin-left: .5em;
}

.node-card .name {
    color: var(--bs-gray-600);
    margin-top: -.2em;
}

.node-card .title {
    font-size: larger;
    margin-top: .2em;
}

.node-card .stories {
    margin-top: .5em;
}

.node-card .stories TIME {
    text-decoration: underline dotted;
}

.node-card .people {
    margin-top: .5em;
}

.node-card .counter {
    display: inline-block;
    margin-right: 1em;
    color: black;
}

.node-card .counter EM {
    font-weight: bold;
    font-style: normal;
}

.node-card .buttons {
    margin-top: .75em;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
