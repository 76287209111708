.posting-sources .title {
    font-size: smaller;
    font-weight: bold;
    border-bottom: solid 1px var(--bs-gray-800);
    color: var(--bs-gray-800);
    margin-bottom: .3em;
}

.posting-sources .source {
    padding: .3em 1em .3em .2em;
    color: var(--bs-primary);
}

.posting-sources .source:hover {
    background-color: var(--highlight);
}

.posting-sources .source A {
    color: inherit;
}

.posting-sources .source .icon {
    display: inline-block;
    width: 1.7em;
    color: var(--bs-gray-600);
}

.posting-sources .source .icon.original {
    color: var(--bs-green);
}
