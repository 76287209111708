#invitation {
    margin-top: 3rem;
    font-size: 2rem;
}

#invitation H1 {
    margin-bottom: 1.5rem;
    text-align: center;
}

@media only screen and (max-width: 575px) {
    #invitation H1 {
        font-size: 1.8rem;
    }
}

#invitation .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

#invitation BUTTON {
    display: block;
    flex: 0 1 auto;
}

#invitation .or {
    padding-left: 1rem;
    padding-right: 1rem;
    flex: none;
}
