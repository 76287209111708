.comments-sentinel-line {
    display: flex;
}

.comments-sentinel {
    flex: auto;
}

.comments-counter {
    flex: none;
    color: var(--bs-gray-500);
}
