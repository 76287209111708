#comments {
    border-top: 1px solid var(--bs-gray-400);
    margin-top: .5rem;
    padding-top: 1rem;
}

#comments.disabled {
    font-style: italic;
    text-align: center;
    margin-bottom: .5rem;
}
