.principal {
    font-size: .8rem;
    color: var(--bs-gray-500);
}

.principal .caption {
    font-size: .9rem;
    font-weight: bold;
}

.principal.senior {
    background-color: var(--bs-gray-500);
    color: white;
    border: 1px solid var(--bs-gray-500);
    border-radius: 5px;
    width: 1.4rem;
    height: 1.65rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
