.entry .entry-image IMG {
    transition: filter .15s ease-in-out;
}

.entry .entry-image:hover IMG {
    filter: brightness(90%);
}

.entry .entry-image.counted {
    position: relative;
}

.entry .entry-image.counted IMG {
    filter: brightness(70%);
}

.entry .entry-image.counted:hover IMG {
    filter: brightness(60%);
}

.entry .entry-image .count {
    display: block;
    position: absolute;
    top: calc(50% - 1ex);
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 200%;
    text-shadow: -2px 2px 4px var(--shadow);
    z-index: var(--zindex-text-overlay);
}
