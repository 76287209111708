:root:root {
    scroll-behavior: auto;
    scrollbar-gutter: stable;
    --bs-font-sans-serif: -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
                          sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

HTML {
    font-size: 90%;
    height: 100%;
}

BODY {
    background-color: var(--body-background);
    padding-bottom: 2rem;
    height: 100%;
}

@media only screen and (max-width: 575px) {
    BODY .dropdown-menu {
        --bs-dropdown-font-size: 1.1rem;
    }
}

BODY.no-scroll {
    overflow: hidden;
}

#app-root {
    height: 100%;
}

A, .btn-link {
    text-decoration: none;
}

ABBR[title] {
    cursor: pointer !important;
}

DETAILS {
    cursor: pointer;
}

DETAILS[open] {
    cursor: unset;
}

BLOCKQUOTE {
    border-left: 3px solid var(--bs-cyan);
    padding: .2rem .2rem .2rem 2.7rem;
    position: relative;
    overflow: hidden;
}

BLOCKQUOTE BLOCKQUOTE {
    padding-left: 1.5rem;
}

BLOCKQUOTE:not(BLOCKQUOTE BLOCKQUOTE)::before {
    content: "\201C";
    font-family: serif;
    font-size: 4rem;
    color: var(--bs-cyan);
    position: absolute;
    left: .5rem;
    top: -1.5rem;
}

BLOCKQUOTE BLOCKQUOTE:last-child, BLOCKQUOTE P:last-child {
    margin-bottom: 0;
}

.emoji {
    background-repeat: no-repeat;
    color: transparent;
    display: inline-block;
    min-width: 1.3em;
}

.container {
    max-width: var(--feed-width);
    padding-left: .25rem;
    padding-right: .25rem;
}

@media only screen and (max-width: 575px) {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

.dialog-help {
    border: solid 1px cornsilk;
    border-radius: 5px;
    padding: .5em;
    margin-bottom: .5rem;
    margin-top: -.5rem;
    background-color: floralwhite;
}

.dialog-help .btn-close {
    float: right;
}

.nav.nav-pills .nav-link.active:hover {
    background-color: var(--bs-primary);
}

.nav.nav-pills .nav-link:hover {
    background-color: var(--highlight-blue);
    cursor: pointer;
}

.nav.nav-pills .nav-link A:hover {
    text-decoration: none;
    color: var(--bs-primary);
}

.content-panel {
    display: flow-root;
    background-color: white;
    border: var(--bs-gray-400) 1px solid;
    border-radius: .25rem;
    padding: 1rem;
}

@media only screen and (max-width: 575px) {
    .content-panel {
        border-left-width: 0;
        border-right-width: 0;
        border-radius: 0;
        padding-bottom: 3rem;
    }
}
