.comment-button, A.comment-button {
    text-decoration: none;
    color: var(--comment-button);
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: .8rem;
}

@media only screen and (min-width: 576px) {
    .comment-button, A.comment-button {
        margin-right: .3rem;
    }

    .comment-button:hover, A.comment-button:hover {
        text-decoration: underline;
    }

    .comment-button .caption, A.comment-button .caption {
        margin-left: .25rem;
    }
}

@media only screen and (max-width: 575px) {
    .comment-button, A.comment-button {
        padding: 0 1rem;
    }

    .comment-button:hover, A.comment-button:hover {
        background-color: var(--highlight-blue);
    }

    .comment-button .caption, A.comment-button .caption {
        display: none;
    }
}
