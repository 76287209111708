.page-share {
    display: inline-block;
    font-size: .7em;
    margin-left: .15em;
    margin-right: .25em;
    background-color: transparent;
    color: var(--bs-gray-400);
    border-width: 0;
    border-radius: 3px;
}

@media only screen and (max-width: 575px) {
    .page-share {
        font-size: .8em;
    }
}

.page-share:hover {
    background-color: var(--highlight);
    color: var(--bs-gray-600);
}
