.news-button {
    position: relative;
}

.news-button .count {
    position: absolute;
    top: -.2rem;
    right: 0;
    background-color: var(--bs-primary);
    color: white;
    font-weight: bold;
    font-size: .7rem;
    padding-left: .2rem;
    padding-right: .2rem;
}

.connection-status .news-button.connection-button.active {
    cursor: pointer;
}
