.signature-verify {
    cursor: pointer;
}

.signature-verify-running {
    color: var(--verifying);
}

.signature-verify-correct {
    color: var(--correct);
}

.signature-verify-incorrect {
    color: var(--incorrect);
}
