.posting-button, A.posting-button {
    flex: auto;
    max-width: 225px;
    text-align: center;
    text-decoration: none;
    color: var(--bs-gray-800);
    padding: .3rem;
    margin-right: .3rem;
    cursor: pointer;
    font-size: 1.3rem;
    background-color: transparent;
    border: none;
    user-select: none;
}

.posting-button:hover, A.posting-button:hover {
    background-color: var(--highlight);
}

.entry A.posting-button:hover {
    text-decoration: none;
}

.posting-button .caption {
    margin-left: .4rem;
    font-weight: 600;
    font-size: 1rem;
}

@media only screen and (max-width: 575px) {
    .posting-button .caption {
        display: none;
    }
}
