.principal-select {
    display: inline-block;
    font-size: .8rem;
    background-color: transparent;
    color: var(--bs-gray-600);
    cursor: pointer;
    padding: .1rem .2rem;
    border: none;
    border-radius: .4rem;
}

.principal-select.long {
    border: solid 1px var(--bs-gray-400);
    border-radius: .75rem;
    padding: .1rem .4rem;
}

.principal-select:hover {
    color: var(--bs-gray-800);
    background-color: var(--highlight);
}

.principal-select:disabled, .principal-select:disabled:hover {
    cursor: auto;
    color: var(--bs-gray-600);
    background-color: var(--bs-gray-400);
}

.principal-select .principal {
    margin-right: .25rem;
    color: var(--bs-gray-600);
}

.principal-select:hover .principal {
    color: var(--bs-gray-800);
}

.principal-select:disabled:hover .principal {
    color: var(--bs-gray-600);
}

.principal-select .chevron {
    font-size: .5rem;
    vertical-align: middle;
}

.principal-select.long .chevron {
    font-size: .6rem;
}

.form-group .principal-select {
    align-self: center;
}
