.posting-not-found {
    background-color: white;
    border: var(--bs-gray-400) 1px solid;
    border-radius: 5px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    display: flex;
}

.posting-not-found .icon {
    margin-right: 1.2rem;
    color: var(--cyan-light);
}

.posting-not-found .message {
    font-size: large;
    color: var(--bs-gray-600);
}
