.comments-rewind {
    border: none;
    background-color: transparent;
    color: inherit;
    position: relative;
    top: .05rem;
    padding: .1rem .3rem;
}

.comments-rewind:hover {
    background-color: var(--highlight);
    color: black;
}
