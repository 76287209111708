.node-name-view {
    margin-top: -.2em;
}

.node-name-view .name {
    color: var(--bs-gray-600);
    margin-right: .5em;
}

.node-name-view .menu {
    display: inline;
    border: 0;
    background: transparent;
}

.node-name-view .menu .chevron {
    color: var(--bs-gray-500);
    cursor: pointer;
}

.node-name-view .menu .chevron:hover {
    color: var(--bs-gray-800);
}
